import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import Box from 'components/Box'
import Button from 'components/Button'
import Container from 'components/Container'
import Heading2 from 'components/Heading2'
import Layout from 'components/Layout'
import Row from 'components/Row'
import Section from 'components/Section'
import Text from 'components/Text'
import * as events from 'constants/events'
import urls from 'constants/urls'
import { useGlobalContext } from 'contexts/GlobalContext'
import EnterpriseReefOrcaLogos from 'features/enterprise/reef-orca/components/EnterpriseReefOrcaLogos'
import useEventPageVisited from 'hooks/use-event-page-visited'
import * as api from 'libs/api'
import * as enterprise from 'libs/enterprise'
import * as url from 'libs/url'
import styles from './Download.module.scss'
import { ReactComponent as StarsSvg } from './images/stars.svg'

/* Not currently used */
export default function DownloadFacebook() {
  useEventPageVisited('Download Facebook')
  const globalContext = useGlobalContext()
  const sourceRef = React.useRef<any>()

  React.useEffect(() => {
    const { ad_id, fbclid, utm_campaign, utm_content, utm_medium, utm_source } =
      url.getQueryString()
    sourceRef.current = {
      ad_id,
      fbclid,
      utm_campaign,
      utm_content,
      utm_medium,
      utm_source,
    }
  }, [])

  async function registerIpForDownload() {
    const response = await fetch('https://api.ipify.org?format=json')
    const data = await response.json()
    if (!data.ip) {
      globalContext.analytics?.trackEvent('Grabbing ip failed', {
        page: 'Download Facebook',
        source: sourceRef.current,
      })
      return
    }

    await api.post('registerIpForAppDownload', {
      ipAddress: data.ip,
      source: sourceRef.current,
    })
  }

  function handleClick() {
    globalContext.analytics?.trackEvent(events.DOWNLOAD_APP_CLICK, {
      page: 'Download Facebook',
      source: sourceRef.current,
    })
    registerIpForDownload()
    window.open(urls.APP_STORE_WITH_PPID, '_blank')
  }

  const valueProps = [
    {
      title: 'Better balance. More strength. Less pain.',
      copy: 'Bold members feel improvements to their strength and balance after just 6 classes.',
    },
    {
      title: 'Choose from hundreds of classes.',
      copy: 'Build a routine that fits your needs. Take classes any time, anywhere.',
    },
    {
      title: 'Designed and taught by experts.',
      copy: 'Your personalized program is designed by our team of physical therapists and coaches.',
    },
    {
      title: 'Track your progress.',
      copy: 'Stay motivated with personalized workout reminders sent directly to your phone.',
    },
  ]

  return (
    <div onClick={handleClick}>
      <Layout className="DownloadFacebook" header={false} footer={false}>
        <header className={styles.hero}>
          <Container size="xxlarge">
            <div className={styles['hero--inner']}>
              <Container align="left" flush size="xmedium">
                <div className={styles['hero--copy']}>
                  <Row size="xmedium">
                    <Row size="xmedium">
                      <Text element="h1" weight="semibold" className={styles.title}>
                        Seated and standing online exercise classes
                      </Text>
                    </Row>
                    <Row size="xmedium">
                      <Text element="p" flush size="large">
                        Designed for older adults, by clinical experts. Unlock FREE access to Bold
                        through select health plans by downloading the app.
                      </Text>
                    </Row>
                    <Button full onClick={handleClick}>
                      Download the app
                    </Button>
                  </Row>
                  <EnterpriseReefOrcaLogos
                    color="black"
                    partners={[enterprise.REEF_KEY, enterprise.ORCA_KEY]}
                  />
                </div>
              </Container>
              <div className={styles['hero--phones']}>
                <StaticImage
                  src="./images/apps@2x.png"
                  alt="Bold app"
                  placeholder="blurred"
                  width={430}
                />
              </div>
            </div>
          </Container>
        </header>
        <Section>
          <Container size="xxlarge">
            <Box align="center" color="purple" className={styles.quote}>
              <Row size="xmedium" aria-hidden>
                <StarsSvg className={styles['quote--stars']} />
              </Row>
              <Text element="blockquote" weight="medium" className={styles['quote--copy']}>
                “I get Bold through my insurance plan (United Healthcare). It offers great variety
                and I learn a lot from the instructors. Helps me stay strong. I feel better on days
                I exercise with Bold. Great company.”
              </Text>
            </Box>
          </Container>
        </Section>
        <Section color="purple">
          <Container size="xxlarge">
            <div className={styles.boxes}>
              {valueProps.map((item) => (
                <Text element="figure" key={item.title} className={styles.box}>
                  <Heading2 level={3} levelStyle={4}>
                    <Text weight="semibold">{item.title}</Text>
                  </Heading2>
                  <Text element="p" flush>
                    {item.copy}
                  </Text>
                </Text>
              ))}
            </div>
          </Container>
        </Section>
      </Layout>
    </div>
  )
}
